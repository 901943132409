import React from 'react'
import { ThemeProvider } from "@mui/material/styles";
import Theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';

 const MuThemeProvider = ({children} :{children: React.ReactNode}) => {
    const theme = Theme ;
    return (
          <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
           </ThemeProvider>
    );
};
export default MuThemeProvider;