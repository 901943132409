import React , {Suspense} from "react"
import { Route , Routes, HashRouter  } from "react-router-dom"
import LinerLoader from "../components/loader"

const Login = React.lazy(  () => import('../views/login') )
const Home = React.lazy(  () => import('../views/home') )
const Detail = React.lazy(  () => import('../views/detail') )
const NotFound = React.lazy(  () => import('../views/notfound') )
const Contact = React.lazy(  () => import('../views/contact') )
const FastMenu = React.lazy(  () => import('../views/fastMenu') )
const Events = React.lazy(  () => import('../views/events') )
const EventDetail = React.lazy(  () => import('../views/eventSingle') )


const RouteList: React.FC = () =>
{
    return(
        <Suspense fallback={<LinerLoader />} >
            <HashRouter >
             <Routes >
                 <Route path='*' element={<NotFound />} />
                 <Route  path="/" element={<Login />} />
                 <Route path="/home" element={<Home />} />
                 <Route path="/home/detail/:url" element={<Detail />} />
                 <Route path="/fastmenu" element={<FastMenu />} />
                 <Route path="/contact" element={<Contact />} />
                 <Route path="/events" element={<Events />} />
                 <Route path="/events/detail/:url" element={<EventDetail />} />                 
             </Routes>
             </HashRouter>
        </Suspense>
    )
}
export default RouteList;