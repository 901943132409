import React from 'react';
import MuThemeProvider from './context/theme/themeProvider';
import RouteList from './routes';
import { SnackbarProvider } from 'material-ui-snackbar-provider'

function App() {
  return (
    <MuThemeProvider >
      <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
         <RouteList />
        </SnackbarProvider>
    </MuThemeProvider>
  );
}

export default App;
