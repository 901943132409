export default function SW() {
  
    let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    if('serviceWorker' in navigator)
    {
        navigator.serviceWorker.register(swUrl).then( res =>{
           
        }).catch( err => {
            console.error(err)
        })
    }

}