import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {          
          primary: {
            main: "#7d63ac",
            light:'#9984BE'
          },
          
          secondary: {
            main: "#37595f ",
            light: "#538791", 

          },
          grey: {
            500: "#909090",
            600: "#636363",
            700: "#494748",
            800: "#4c5b62",
            900: "#333",
          },
          background: {
             default: "#ccc",
             paper: "#ffffff",
          
          },
          text: {
            primary: "#333",
            secondary: "#999",
            
          },
        
  },
  spacing: [0, 4, 8, 16, 32, 64, 128],
  typography: {
  fontSize: 16,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 16,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "unset!important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
      root: {
          boxShadow: "unset!important",
          minHeight: "55px",
          borderRadius: 8,
          fontSize:14,
          textTransform:'capitalize'
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
      root: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
              root: {
                background: "#ffffff",
              },
      },
    },
  },
})

export default Theme